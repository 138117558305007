@use "sass:math";
@use "variables";
@use "functions";
@use "mixins";
@use "bootstrap";

b,
strong {
  font-weight: variables.$font-weight-bold;
}
a {
  text-decoration: none;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.cursor-pointer {
  cursor: pointer !important;
}

.form-input-field {
  &:focus {
    border: 2px solid variables.$primary-1 !important;
  }
}

.error {
  bottom: -30px;
  position: absolute;
  color: variables.$critical-500;
}

.paper-container {
  padding: variables.$page-padding;
  border-radius: variables.$border-radius-lg;
}

.font-secondary {
  & > * {
    font-family: variables.$font-family-secondary;
  }
}

.submit-btn {
  height: 48px;
}

.empty-content-container {
  height: calc(100vh - #{variables.$header-height} - #{variables.$footer-height});
}

// Bootstrap

// Buttons

.btn {
  &:focus {
    border-color: variables.$surface-1 !important;
    box-shadow: none !important;
    /*box-shadow: 0 0 0 bootstrap.$btn-focus-width bootstrap.$btn-focus-box-shadow !important;*/
  }

  &.without-border {
    border-color: transparent;
  }

  &.loading {
    position: relative;

    &::after {
      @extend .spinner-border, .spinner-border-sm;

      $size: bootstrap.$line-height-base * 1em;

      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: $size;
      height: $size;
      margin-top: math.div($size, -2);
      margin-left: math.div($size, -2);
    }

    > * {
      visibility: hidden;
    }
  }

  &[disabled],
  &.disabled {
    color: variables.$foreground-disabled;
    background-color: variables.$element-disabled;
    border-color: variables.$element-disabled;
  }
}

.btn-primary {
  &:hover {
    background-color: variables.$primary-1;
  }

  &:active {
    background-color: variables.$primary-1;
    border-color: variables.$primary-1;
  }
}

.btn-outline-primary {
  &:hover {
    color: bootstrap.$primary;
    background-color: variables.$secondary-1;
  }

  &:active {
    color: variables.$primary-1;
    background-color: transparent;
    border-color: variables.$primary-1;
  }
}

.btn-outline-danger {
  &:hover {
    color: bootstrap.$danger;
    background-color: variables.$critical-050;
  }

  &:active {
    color: variables.$critical-900;
    background-color: transparent;
    border-color: variables.$critical-900;
  }
}

.btn-outline-dark {
  &:hover {
    background-color: variables.$additional-12-100;
    color: variables.$foreground-high;
  }
  &:active {
    background-color: variables.$additional-13-200!important;
    color: variables.$foreground-high!important;
  }
}

.dropdown-toggle {
  &::after {
    display: none;
  }
}

.dropdown-menu {
  width: 100%;
  padding: 0;
}

.dropdown-item {
  margin: 2px 0;
  padding: 10px 12px;
  border-radius: variables.$border-radius;

  &:hover {
    color: variables.$foreground-high;
    background-color: variables.$secondary-1;
  }

  &:focus,
  &:focus-visible {
    background-color: variables.$secondary-1;
    outline: 1px solid variables.$primary-1;
    color: variables.$primary-1;
  }
}

// Forms

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.form-control {
  max-height: 48px;
  text-align: left;

  &:not(:disabled):not([readOnly]):hover {
    border-color: bootstrap.$input-hover-border-color;
  }

  &[readOnly] {
    color: variables.$foreground-low !important;
    background-color: transparent;
    font-style: italic;
    cursor: default;

    &:focus {
      box-shadow: none;
    }
  }

  &[type="button"] {
    padding-right: map-get(bootstrap.$spacers, 5);
  }

  &[type="date"] {
    &::-webkit-calendar-picker-indicator {
      $size: functions.get-em(24px, bootstrap.$input-btn-font-size);

      width: $size;
      height: $size;
      background-image: url("./icons/calendar.svg");
      padding: 0;
    }
  }
}

.form-group {
  margin-bottom: functions.get-rem(40px);
}

.form-switch {
  display: flex;
  align-items: center;
  font-size: functions.get-rem(bootstrap.$form-switch-font-size);
  padding: functions.get-em(8px, bootstrap.$form-switch-font-size)
    functions.get-em(9px, bootstrap.$form-switch-font-size);

  .form-check-input {
    cursor: pointer;
    height: functions.get-em(bootstrap.$form-switch-height, bootstrap.$form-switch-font-size);
    border: none;
    background-color: variables.$additional-1-550;
    background-size: auto
      calc(100% - #{functions.get-em(bootstrap.$form-switch-bg-offset * 2, bootstrap.$form-switch-font-size)});
    background-position: functions.get-em(bootstrap.$form-switch-bg-offset, bootstrap.$form-switch-font-size) center;
    margin: 0 functions.get-em(8px, bootstrap.$form-switch-font-size) 0 0;

    &:checked {
      background-color: variables.$positive-500;
      background-position: calc(
          100% - #{functions.get-em(bootstrap.$form-switch-bg-offset, bootstrap.$form-switch-font-size)}
        )
        center;
    }
  }
}

.input-group {
  &:not(.has-validation) {
    > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.is-invalid):not(.is-valid),
    > .dropdown-toggle:nth-last-child(n + 3) {
      border-right: bootstrap.$input-bg;
    }
  }

  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(
      .invalid-feedback
    ) {
    border-left: none;
    margin-left: 0;
  }
}

.input-group-text {
  svg {
    color: inherit;
    //margin: 0 functions.get-em(-2px, bootstrap.$input-btn-font-size);

    [fill] {
      fill: currentColor;
    }

    [stroke] {
      stroke: currentColor;
    }
  }
}

// Navs

.nav-link {
  padding: map-get(bootstrap.$spacers, 1) map-get(bootstrap.$spacers, 2);

  .nav-pills & {
    display: flex;
    align-items: center;
    line-height: 1.5;

    svg {
      color: inherit;

      &:first-child {
        margin-right: functions.get-rem(8px);
      }

      &:last-child {
        margin-left: functions.get-rem(8px);
      }

      /*[fill] {
        fill: currentColor;
      }*/

      /* [stroke] {
        stroke: currentColor;
      }*/
    }
  }
}

// Accordion

.accordion-button {
  font-size: inherit;
}

.bold {
  font-weight: variables.$font-weight-bold !important;
}

.italic {
  font-style: italic !important;
}

.underline {
  text-decoration: underline !important;
}

.font-weight-normal {
  font-weight: variables.$font-weight-medium !important;
}

.row,
.col {
  margin-top: 0;
  margin-bottom: 0;
}

.flag-dropdown {
  border: none !important;
  height: 38px;
  top: -10px !important;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
}

.selected-flag {
  width: 60px !important;
  & > .flag {
    left: 50%;
    transform: scale(1.7);
    & > .arrow {
      left: -60%;
      position: absolute;
    }
  }
}

.form-check-input {
  border-color: variables.$foreground-high;
  &[type="radio"] {
    background-color: white;
    cursor: pointer;
  }
}
// SVG
svg {
  transition: all 0.2s;
  &[direction="top"] {
    transform: rotate(180deg);
  }
  &[direction="bottom"] {
    transform: rotate(0deg);
  }
  &[direction="left"] {
    transform: rotate(90deg);
  }
  &[direction="right"] {
    transform: rotate(-90deg);
  }

  @each $key in map-keys(variables.$stroke-width) {
    &[stroke-width="#{$key}"] {
      path {
        stroke-width: map-get(variables.$stroke-width, $key);
      }
    }
  }
}

.w-max-content {
  width: max-content !important;
}

;@import "sass-embedded-legacy-load-done:825";